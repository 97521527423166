<template>
  <div class="wrapper" :class="{zoom: zoom}">
    <img v-show="visible" :src="src" ref="source" :style="imgStyle" @dblclick="zoom=!zoom">
    <circle1 v-show="!visible && !isError"></circle1>
    <div class="bar" v-show="visible">
      <span @click="backRotate" class="back"><i-icon name="icon-rotate"></i-icon></span>
      <span @click="rotateImg"><i-icon name="icon-rotate"></i-icon></span>
      <a :href="src" target="_blank" download="image.jpg"><i class="el-icon-download" style="transform:scale(1.25)"></i></a>
      <span @click="zoom=!zoom" v-if="!zoom"><i class="el-icon-zoom-in" /></span>
      <span @click="zoom=!zoom" v-if="zoom"><i class="el-icon-zoom-out" /></span>
    </div>

    <i-icon v-if="isError" class="error" name="icon-tupianposun"></i-icon>
  </div>
</template>

<script>
import { Circle as Circle1 } from 'vue-loading-spinner'
export default {
  name: 'imageViewer',
  props: {
    src: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      visible: false,
      isError: false,
      zoom: false,
      angle: 0
    }
  },
  computed: {
    imgStyle() {
      if (Math.abs(this.angle % 180) == 90) {
        return {
          maxHeight: '100vw',
          maxWidth: '100vh',
          transform: `rotate(${this.angle}deg)`
        }
      } else {
        return {
          maxHeight: '100vh',
          maxWidth: '100vw',
          transform: `rotate(${this.angle}deg)`
        }
      }

    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.source.onload = this.loaded
      this.$refs.source.onerror = () => {
        this.isError = true
      }
    })
  },
  methods: {
    loaded() {
      this.visible = true
    },
    rotateImg() {
      this.angle += 90
    },
    backRotate() {
      this.angle -= 90
    }
  },
  components: {
    Circle1
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  img {
    max-width: 100vw;
    max-height: 100vh;
    user-select: none;
    display: block;
    margin: 0 auto;
    transition: all .3s linear;
  }
  .bar {
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, .25);
    color: #EEE;
    & > span,a {
      width: 84px;
      display: inline-block;
      height: 84px;
      line-height: 84px;
      font-size: 28px;
      text-align: center;
      appearance: none;
      color: #EEE;
      &:visited {
        color: #EEE;
      }
      &:hover {
        color: #fff;
        background-color: rgba(0, 0, 0, .35);
        cursor: pointer;
      }
      &.back {
        transform: rotateY(180deg);
      }
    }
  }
  .error {
    font-size: 128px;
    color: #DDD;
  }
  &.zoom {
    overflow: auto;
    img {
      max-width: unset !important;
      max-height: unset !important;
    }
  }
}
</style>

